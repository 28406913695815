import React from 'react';
import './App.css';

function HashFail() {

  return (
      <div className="container transaction-container">
          <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                  <div className="transaction-form">
                      Error: this application was called from an invalid source.
                  </div>
              </div>
          </div>
      </div>
  );
}

export default HashFail;
