import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import HashFail from './HashFail';
import * as serviceWorker from './serviceWorker';

const axios = require('axios');
const CryptoJS = require('crypto-js');
const urlVars = getUrlVars();
//IMPORTANT: REQUEST HASH MUST NOT BE FIRST URL PARAM (i.e. must not be ?requestHash=12345, must be &requestHash=12345)
const clientId = urlVars['clientId']; 
const requestHash = urlVars['requestHash']; 
const additionalInfoEncrypted = urlVars['additionalInfo']; 

const checkHash = async function() {
  return await makeCheckHashCall()
  .then((res) => {
      if(res.data.status) {
        return res.data.status;
      }
      else {
        return false;
      }
  })
  .catch(function (error) {
    return false;
  });
}

const makeCheckHashCall = () => {
    return axios.post('/api/checkHash', {
        requestUrl: window.location.href,
        requestHash,
        clientId
    })
}

const decryptAdditionalInfo = async function() {
  return await makeDecryptAdditionalInfoCall()
  .then((res) => {
    if(res.status == 200) {
      return res.data;
    }
    else {
      return '';
    }
  })
  .catch(function (error) {
    return '';
  })
}

const getClientDetails = async function() {
  return await makeGetClientDetailsCall()
  .then((res) => {
    if(res.status == 200) {
      return res.data;
    }
    else {
      return null;
    }
  })
  .catch(function (error) {
    return null;
  })
}

const makeDecryptAdditionalInfoCall = () => {
  return axios.get('/api/decryptAdditionalInfo?clientId=' + clientId + '&additionalInfoEncrypted=' + additionalInfoEncrypted);
}

const makeGetClientDetailsCall = () => {
  return axios.get('/api/getClientDetails?clientId=' + clientId);
}

function getUrlVars() {
  let vars = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
    vars[key] = value;
  });

  return vars;
}

(async () => {
  const hashCheckResult = await checkHash();
  const additionalInfo = additionalInfoEncrypted ? await decryptAdditionalInfo(clientId, additionalInfoEncrypted) : '';
  const additionalInfoBase64 = additionalInfo ? CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(additionalInfo))) : '';
  const client = await getClientDetails(clientId);
  const clientBase64 = client ? CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(client))) : null;

  if(hashCheckResult) {
    ReactDOM.render(<App clientBase64={clientBase64} additionalInfoBase64={additionalInfoBase64} />, document.getElementById('root'));
  }
  else {
    ReactDOM.render(<HashFail />, document.getElementById('root'));
  }
})()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


