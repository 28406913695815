import React, { useRef, useState } from 'react';
import './App.css';
import * as $ from 'jquery';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
const axios = require('axios');
const CryptoJS = require('crypto-js');

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('./images', false, /\.(png|jpe?g|svg)$/));

//Keeping to reenable if needed
//axios.defaults.timeout = 5000;

function App(props) {
  //TODO: swap with react router
  const urlVars = getUrlVars();
  const transactionId = urlVars['transactionId'];
  const webhookUrl = urlVars['webhookUrl'];
  const redirectUrl = urlVars['redirectUrl'] ? decodeURIComponent(urlVars['redirectUrl']) : null;
  const clientIdParam = urlVars['clientId'];
  const frequency = urlVars['frequency'];
  const transactionType = urlVars['transactionType'];
  const amountParam = urlVars['amount'];

  const client = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(props.clientBase64)));
  let logo = images[client['client-background-image']];

  let firstNameParam;
  let lastNameParam;
  let emailParam;
  let billingAddress1Param;
  let billingAddress2Param;
  let billingCityParam;
  let billingStateParam;
  let billingPostalCodeParam;
  let billingCountryParam;
  let ipAddressParam;

  if(props.additionalInfoBase64) {
    const additionalInfoObject = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(props.additionalInfoBase64)));

    firstNameParam = additionalInfoObject.billing_first_name;
    lastNameParam = additionalInfoObject.billing_last_name;
    emailParam = additionalInfoObject.billing_email;
    billingAddress1Param = additionalInfoObject.billing_street_1;
    billingAddress2Param = additionalInfoObject.billing_street_2;
    billingCityParam = additionalInfoObject.billing_city;
    billingStateParam = additionalInfoObject.billing_state;
    billingPostalCodeParam = additionalInfoObject.billing_postal_code;
    billingCountryParam = additionalInfoObject.billing_country;
    ipAddressParam = additionalInfoObject.ip_address;
  }

  let transactionIdUnique;
  let countdownValue = 5;

  let transactionProfileStyle = {
    backgroundImage: "url(" + logo + ")",
    backgroundSize: client['client-background-size'],
    border: client['client-border']
  }

  const [errors, setErrors] = useState({});

  // user info form
  const amountRef = useRef();
  const [amount, setAmount] = useState(amountParam);

  const firstNameRef = useRef();
  const [firstName, setFirstName] = useState(firstNameParam);

  const lastNameRef = useRef();
  const [lastName, setLastName] = useState(lastNameParam);

  const emailRef = useRef();
  const [email, setEmail] = useState(emailParam);

  // billing info form
  const billingAddress1Ref = useRef();
  const [billingAddress1, setBillingAddress1] = useState(billingAddress1Param);

  const billingAddress2Ref = useRef();
  const [billingAddress2, setBillingAddress2] = useState(billingAddress2Param);

  const billingCityRef = useRef();
  const [billingCity, setBillingCity] = useState(billingCityParam);

  const billingStateRef = useRef();
  const [billingState, setBillingState] = useState(billingStateParam);

  const billingPostalRef = useRef();
  const [billingPostal, setBillingPostal] = useState(billingPostalCodeParam);

  const billingCountryRef = useRef();
  const [billingCountry, setBillingCountry] = useState(billingCountryParam);

  // credit card info
  const creditCardRef = useRef();
  const [creditCard, setCreditCard] = useState();

  const expirationMonthRef = useRef();
  const [expirationMonth, setExpirationMonth] = useState();

  function onExpirationMonthInput(e) {
    let expirationMonth = e.target.value;
    if(expirationMonth.length != 2) {
      errors.expmonth = 'Exp month must be 2 numbers long.';
    }
    else {
      errors.expmonth = null;
    }

    setErrors(errors);
  }

  function onExpirationYearInput(e) {
    let expirationYear = e.target.value;
    if(expirationYear.length != 2 && expirationYear.length != 4) {
      errors.expyear = 'Exp year must be 2 or 4 numbers long.';
    }
    else {
      errors.expyear = null;
    }

    setErrors(errors);
  }

  const expirationYearRef = useRef();
  const [expirationYear, setExpirationYear] = useState();

  const cvvRef = useRef();
  const [cvv, setCVV] = useState();

  const [errorMsg, setErrorMsg] = useState();

  function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
    });

    return vars;
  }

  function oneToTwo (event) {
      event.preventDefault();
      if (amount && email && firstName && lastName) {
          $(".transaction-form-step-1").fadeOut(function () {
              $(".transaction-form-step-2").fadeIn();
          });
      }
  }

  function twoToOne (event) {
      event.preventDefault();
      $(".transaction-form-step-2").fadeOut(function () {
          $(".transaction-form-step-1").fadeIn();
      });
  }

  function twoToThree (event) {
      event.preventDefault();
      if (billingAddress1 && billingCity && billingState && billingPostal && billingCountry) {
          $(".transaction-form-step-2").fadeOut(function () {
              $(".transaction-form-step-3").fadeIn();
          });
      }
  }

  function threeToTwo (event) {
      event.preventDefault();
      $(".transaction-form-step-3").fadeOut(function () {
          $(".transaction-form-step-2").fadeIn();
      });
  }

  function errorToThree (event) {
      event.preventDefault();

      setErrorMsg(null);
      $(".transaction-form-error").fadeOut(function () {
          $(".transaction-form-step-3").fadeIn();
      });
  }

  function showFormSuccess() {
      $('#cover-spin').hide();
      $(".transaction-form-step-3").fadeOut(function () {
          $(".transaction-form-success").fadeIn();
      });
  }

  function showFormError() {
      $('#cover-spin').hide();
      $(".transaction-form-step-3").fadeOut(function () {
          $(".transaction-form-error").fadeIn();
      });
  }

  function stepOneKeyPress (event) {
      if(event.key === 'Enter') {
          event.preventDefault();
          $("button.transaction-next-btn-1").click();
      }
  }

  function stepTwoKeyPress (event) {
      if(event.key === 'Enter') {
          event.preventDefault();
          $("button.transaction-next-btn-2").click();
      }
  }

  function stepThreeKeyPress (event) {
      if(event.key === 'Enter') {
          event.preventDefault();
          $("button.transaction-next-btn-3").click();
      }
  }

  function redirectButtonClicked(event) {
      event.preventDefault();
      window.top.location.href = redirectUrl;
  }

  function startRedirectCountdown() {
      $('div.transaction-redirect-wrapper span.transaction-redirect-countdown-value').text(countdownValue);
      $('div.transaction-redirect-wrapper').fadeIn();
      countdownRedirectStep();

  }

  function countdownRedirectStep() {
      setTimeout(function(){
        countdownValue--;
        if(countdownValue === 0) {
            window.top.location.href = redirectUrl;
        }
        else {
            $('div.transaction-redirect-wrapper span.transaction-redirect-countdown-value').text(countdownValue);
            countdownRedirectStep();
        }
      }, 1000);
  }

  const makeVantage1Call = () => {
      return axios.post('/api/vantage1', {
          origin: window.location.origin,
          webhookUrl,
          transactionId,
          transactionIdUnique: transactionIdUnique,
          amount,
          first_name: firstName,
          last_name: lastName,
          email,
	  billingState,
          client_id: clientIdParam,
          frequency,
          transactionType,
          ipAddress: ipAddressParam
      })
  }

  const makeVantage2Call = (url) => {
      const bodyFormData = new FormData();
      bodyFormData.set('billing-cc-number', creditCard);
      bodyFormData.set('billing-cc-exp', expirationMonth + expirationYear);
      bodyFormData.set('billing-cc-number', creditCard);
      bodyFormData.set('billing-cc-number', creditCard);
      bodyFormData.set('billing-cvv', cvv);
      bodyFormData.set('billing-first-name', firstName);
      bodyFormData.set('billing-last-name', lastName);
      bodyFormData.set('billing-address1', billingAddress1);
      bodyFormData.set('billing-address2', billingAddress2);
      bodyFormData.set('billing-city', billingCity);
      bodyFormData.set('billing-state', billingState);
      bodyFormData.set('billing-postal', billingPostal);
      bodyFormData.set('billing-country', billingCountry);
      bodyFormData.set('billing-email', email);

      return axios.post(
          url,
          bodyFormData
      );
  }

  const makeVantage2ErrorCall = (resultNumber, resultCode, resultText) => {
      return axios.post('/api/vantage2Error', {
          webhookUrl,
          transactionId,
          transactionIdUnique: transactionIdUnique,
          amount,
          resultNumber,
          resultCode,
          resultText,
          frequency,
          transactionType
      })
  }

  const standardErrorMsg = 'There was a problem processing your transaction.\n\nPlease contact Gunspring support at support@gunspring.com and send them this error.';
  const gunspringErrorMsg = 'Cannot communicate with Gunspring server.\n\nPlease contact Gunspring support at support@gunspring.com and send them this error.';

  const setErrorMsgWithDetails = function(transactionIdUnique, resultNumber, resultCode, errorMessage) {
      errorMessage += '\n\nGunspring error code: ' + transactionIdUnique + '/' + resultNumber + '/' + resultCode;
      setErrorMsg(errorMessage);
  }

  const processTransaction = async function(event) {
      event.preventDefault();

      $('#cover-spin').show(0);

      transactionIdUnique = transactionId + '_' + Date.now();

      if (!firstName || !lastName ||
          !billingAddress1 || !billingCity || !billingState || !billingPostal || !billingCountry ||
          !creditCard || !expirationMonth || !expirationYear || !cvv
      ) {
          setErrorMsgWithDetails(transactionIdUnique, '0', '000', 'One or more required parameters were missing.');
          showFormError();
          return;
      }

      //MAKE CALL TO VANTAGE 1
      let vantage1Status, url, vantage1Error;
      await makeVantage1Call()
      .then((res) => {
          vantage1Status = res.status;
          vantage1Error = res.data.error ? res.data.error : '';
          url = res.data.url ? res.data.url : '';
      })
      .catch(function (error) {
          vantage1Status = 400;
          vantage1Error = error.message;
      });

      if (vantage1Status !== 200) {
          console.log(vantage1Error);
          
          if(vantage1Status === 298) {
            setErrorMsg(vantage1Error);
          }
          else if(vantage1Status !== 299) {
              setErrorMsgWithDetails(transactionIdUnique, '?', vantage1Status, gunspringErrorMsg);
          }
          else {
              setErrorMsgWithDetails(transactionIdUnique, '?', vantage1Status, standardErrorMsg);
          }

          showFormError();
          return;
      }

      //MAKE CALL TO VANTAGE 2
      let vantage2Response, vantage2ResultNumber, vantage2ResultCode, vantage2or3Error, vantage2FailedIn2 = false, vantage2FailedIn2or3 = false, vantage2FailedIn3 = false;

      await makeVantage2Call(url)
      .then((res) => {
          vantage2Response = res;
      }).catch((error) => {
          //VANTAGE 2 CALL CONNECTION ERR
          //We should ONLY end up here if we had an exception calling vantage 2
          //Failures in vantage 3 or legitimate non-success vantage 2 returns handled later

          vantage2FailedIn2 = true;

          vantage2ResultNumber = -4;
          vantage2ResultCode = -420;
          vantage2or3Error = error.message;
      });

      console.log(vantage2Response);

      //FIGURE OUT THE RESPONSE FROM VANTAGE 2
      try {
          //  Vantage 2 succeeded but we had a failure in Vantage 3. Show the error.
          if(vantage2Response && vantage2Response.status === 299) {
              vantage2FailedIn3 = true;
              vantage2or3Error = vantage2Response.data.error;
          }
          //  Vantage 2 call returned 200 Invalid Session. Show the error.
          else if (vantage2Response &&
                   vantage2Response.status === 200 &&
                   vantage2Response.data &&
                   JSON.stringify(vantage2Response.data).indexOf('Invalid Session') !== -1
          ) {
              vantage2FailedIn2 = true;
              vantage2or3Error = 'Vantage 2 - Invalid Session';
              vantage2ResultNumber = -1;
              vantage2ResultCode = -120;
          }
          //  Vantage 2 call returned some non-200 message (that's also not 299)
          else if (vantage2Response &&
                   vantage2Response.status !== 200
          ) {
              vantage2FailedIn2 = true;
              vantage2or3Error =  "Vantage 2 Non-200 Response: " + vantage2Response.status + ". Body follows: " + vantage2Response.data;
              vantage2ResultNumber = -1;
              vantage2ResultCode = -120;
          }
      }
      catch(error) {
          vantage2FailedIn2or3 = true;
          vantage2or3Error = error.message;
          vantage2ResultNumber = -1;
          vantage2ResultCode = -120;
      }

      //If we had a failure in vantage 2, report it
      if(vantage2FailedIn2 || vantage2FailedIn2or3) {
          try {
            await makeVantage2ErrorCall(vantage2ResultNumber, vantage2ResultCode, vantage2or3Error);
          }
          catch(error) {
            //If we fail at reporting it, all we can do is display a better error.
            vantage2or3Error += ', ' + error;
          }
      }

      //If we have an error from either vantage 2 or vantage 3, display it
      if(vantage2FailedIn2 || vantage2FailedIn3) {
          console.log(vantage2or3Error);

          if(vantage2FailedIn2) {
              setErrorMsgWithDetails(transactionIdUnique, vantage2ResultNumber, vantage2ResultCode, standardErrorMsg);
          }
          else {
              setErrorMsg(vantage2or3Error);
          }

          showFormError();
          return;
      }

      //GOT A SUCCESS - SHOW SUCCESS AND REDIRECT
      showFormSuccess();

      try {
          if (redirectUrl) {
              setTimeout(function(){
                startRedirectCountdown();
              }, 2000);
          }
      }
      catch(error) {
          //If redirect fails, just stay on the page
      }
  }

  const FormFooter = () => (
      <div className="transaction-powered-msg-line">
          <div className="transaction-powered-msg-container">
              <p className="transaction-powered-msg">
                  Powered by <span><a href="https://gunspring.com">Gunspring</a></span>
              </p>
          </div>
      </div>
  );

  const FormSuccess = () => (
      <div className="transaction-form-success">

          <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>

          <p className="transaction-sent-msg">Transaction sent</p>

          <div className="transaction-redirect-wrapper">
            <p className="transaction-redirect-countdown">
              Redirecting to {client['client-app-name']} in&nbsp;
              <span className="transaction-redirect-countdown-value"></span>
              ...
            </p>

            <button onClick={(event) => redirectButtonClicked(event)} className="transaction-redirect-button">
              Redirect Now
            </button>
          </div>

          <FormFooter />
      </div>
  );

  const FormError = () => (
      <div className="transaction-form-error">

          <svg version="1.1" className="xbox" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
              <circle className="path circle" cx="65.1" cy="65.1" r="62.1"/>
              <line className="path line" fill="none" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
              <line className="path line" fill="none" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
          </svg>

          <p className="transaction-error-msg">{errorMsg}</p>

          <div className="row">
              <div className="col-12">
                  <button className="transaction-next-btn transaction-back-btn-3" onClick={(event) => errorToThree(event)}>Back</button>
              </div>
          </div>

          <FormFooter />
      </div>
  );

  if (!clientIdParam) {
      return (
          <div className="container transaction-container">
              <div className="row justify-content-center">
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                      <div className="transaction-form">
                          Error: No client id provided
                      </div>
                  </div>
              </div>
          </div>
      );
  }

  if (!transactionId) {
      return (
          <div className="container transaction-container">
              <div className="row justify-content-center">
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                      <div className="transaction-form">
                          Error: No transaction id provided
                      </div>
                  </div>
              </div>
          </div>
      );
  }

  if (frequency !== 'SINGLE' && frequency !== 'MONTHLY') {
      return (
          <div className="container transaction-container">
              <div className="row justify-content-center">
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                      <div className="transaction-form">
                          Error: "frequency" parameter must be either "SINGLE" or "MONTHLY".
                      </div>
                  </div>
              </div>
          </div>
      );
  }

  if (transactionType !== 'SALE' && transactionType !== 'AUTHORIZE') {
      return (
          <div className="container transaction-container">
              <div className="row justify-content-center">
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                      <div className="transaction-form">
                          Error: "transactionType" parameter must be either "SALE" or "AUTHORIZE".
                      </div>
                  </div>
              </div>
          </div>
      );
  }

  if (transactionType === 'AUTHORIZE' && frequency !== 'SINGLE') {
      return (
          <div className="container transaction-container">
              <div className="row justify-content-center">
                  <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                      <div className="transaction-form">
                          Error: "AUTHORIZE" transactions can only have "SINGLE" frequency
                      </div>
                  </div>
              </div>
          </div>
      );
  }

  return (
    <div>
      <div id="cover-spin"></div>
      <div className="container transaction-container">
          <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-5 col-xl-4">
                  <div className="transaction-form">
                      <div className="transaction-profile-container">
                          <div 
                            className="transaction-profile"
                            style={transactionProfileStyle}
                          ></div>
                          <div className="transaction-title">{client['client-app-name']}</div>
                      </div>
                      <form>
                          <div onKeyPress={(event)=>stepOneKeyPress(event)} className="transaction-form-step-1">
                              {/*<div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fas fa-hashtag fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder={frequency}
                                      name="transaction-frequency"
                                      required
                                      readOnly={true}
                                  />
                              </div>*/}

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fas fa-dollar-sign fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="number"
                                      className="form-control"
                                      placeholder={amount ? amount : "Amount"}
                                      name="transaction-amount"
                                      step="0.01"
                                      ref={amountRef}
                                      onChange={() => setAmount(amountRef.current.value)}
                                      required
                                      readOnly={(amountParam !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fas fa-envelope fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="email"
                                      className="form-control"
                                      placeholder={email ? email : "Email"}
                                      name="transaction-email"
                                      ref={emailRef}
                                      onChange={() => setEmail(emailRef.current.value)}
                                      required
                                      readOnly={(emailParam !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fas fa-user fa-fw"></i></div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder={firstName ? firstName : "First name"}
                                      name="transaction-first-name"
                                      ref={firstNameRef}
                                      onChange={() => setFirstName(firstNameRef.current.value)}
                                      required
                                      readOnly={(firstNameParam !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fas fa-user fa-fw"></i></div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder={lastName ? lastName : "Last name"}
                                      name="transaction-last-name"
                                      ref={lastNameRef}
                                      onChange={() => setLastName(lastNameRef.current.value)}
                                      required
                                      readOnly={(lastNameParam !== undefined)}
                                  />
                              </div>

                              <button className="transaction-next-btn transaction-next-btn-1" onClick={(event) => oneToTwo(event)}>Next</button>

                              <FormFooter />
                          </div>

                          <div onKeyPress={(event) => stepTwoKeyPress(event)} className="transaction-form-step-2">
                            <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fa fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <CountryDropdown
                                      className="form-control"
                                      id="billingCountry"
                                      value={billingCountry}
                                      name="transaction-billing-country"
                                      ref={billingCountryRef}
                                      onChange={(val) => setBillingCountry(val)}
                                      required 
                                      disabled={(billingCountryParam !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fa fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder={billingAddress1 ? billingAddress1 : "Billing Address 1"}
                                      name="transaction-billing-address-1"
                                      ref={billingAddress1Ref}
                                      onChange={() => setBillingAddress1(billingAddress1Ref.current.value)}
                                      required 
                                      readOnly={(billingAddress1Param !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fa fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder={billingAddress2 ? billingAddress2 : "Billing Address 2"}
                                      name="transaction-billing-address-2"
                                      ref={billingAddress2Ref}
                                      onChange={() => setBillingAddress2(billingAddress2Ref.current.value)}
                                      readOnly={(billingAddress2Param !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fa fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder={billingCity ? billingCity : "Billing City"}
                                      name="transaction-billing-city"
                                      ref={billingCityRef}
                                      onChange={() => setBillingCity(billingCityRef.current.value)}
                                      required 
                                      readOnly={(billingCityParam !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fa fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <RegionDropdown
                                      className="form-control"
                                      id="billingState"
                                      country={billingCountry}
                                      value={billingState}
                                      name="transaction-billing-state"
                                      ref={billingStateRef}
                                      onChange={(val) => setBillingState(val)}
                                      disabled={(billingStateParam !== undefined)}
                                  />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="fa fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder={billingPostal ? billingPostal : "Billing Postal Code"}
                                      name="transaction-billing-postal"
                                      ref={billingPostalRef}
                                      onChange={() => setBillingPostal(billingPostalRef.current.value)}
                                      required 
                                      readOnly={(billingPostalCodeParam !== undefined)}
                                  />
                              </div>
 
                              <div className="row">
                                  <div className="col-6">
                                      <button className="transaction-next-btn transaction-back-btn-2" onClick={(event) => twoToOne(event)}>Back</button>
                                  </div>
                                  <div className="col-6">
                                      <button className="transaction-next-btn transaction-next-btn-2" onClick={(event) => twoToThree(event)}>Next</button>
                                  </div>
                              </div>

                              <FormFooter />
                          </div>

                          <div onKeyPress={(event) => stepThreeKeyPress(event)} className="transaction-form-step-3">

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="far fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Credit card number"
                                      name="transaction-credit-card-number"
                                      ref={creditCardRef}
                                      onChange={() => setCreditCard(creditCardRef.current.value)}
                                      required />
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="far fa-calendar-alt fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Expiration month (MM)"
                                      name="transaction-expiration-month"
                                      ref={expirationMonthRef}
                                      onChange={() => setExpirationMonth(expirationMonthRef.current.value)}
	  			      onInput={onExpirationMonthInput}
                                      required />
				  {errors.expmonth && <div className="errortext">{errors.expmonth}</div>}
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="far fa-calendar-alt fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Expiration year (YYYY)"
                                      name="transaction-expiration-year"
                                      ref={expirationYearRef}
                                      onChange={() => setExpirationYear(expirationYearRef.current.value)}
	  			      onInput={onExpirationYearInput}
                                      required />
				  {errors.expyear && <div className="errortext">{errors.expyear}</div>}
                              </div>

                              <div className="input-group field-space">
                                  <div className="input-group-prepend">
                                      <div className="input-group-text"><i className="far fa-credit-card fa-fw"></i>
                                      </div>
                                  </div>
                                  <input
                                      type="number"
                                      className="form-control"
                                      placeholder="CVV"
                                      name="transaction-cvv"
                                      ref={cvvRef}
                                      onChange={() => setCVV(cvvRef.current.value)}
                                      required />
                              </div>

                              <div className="row">
                                  <div className="col-6">
                                      <button className="transaction-next-btn transaction-back-btn-3" onClick={(event) => threeToTwo(event)}>Back</button>
                                  </div>
                                  <div className="col-6">
                                      <button 
	  			          className="transaction-next-btn transaction-next-btn-3" 
	  				  disabled={errors.expmonth || errors.expyear}
	  				  onClick={(event) => processTransaction(event)}>
	  			          Submit
	  			      </button>
                                  </div>
                              </div>

                              <FormFooter />
                          </div>

                          <FormSuccess />

                          <FormError />
                      </form>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
}

export default App;
